import tokenService from "./tokenService";
import Axios from "axios";

let API_ROOT = "";
let IMAGE_ROOT = "";
export let HOME_ROOT = "";
export let PATIENT_ROOT = "";
export let SHARE_LINK_LOGIN = "";
export let SHARE_LINK_REGISTER = "";
if (process.env.NODE_ENV === "production") {
  //API_ROOT = "https://healthswimprodapi.azurewebsites.net/api";

  //production
  // API_ROOT = "https://healthswimapi-v3.azurewebsites.net/api";

  //staging
  // API_ROOT = "https://healthswimapi20220620214913.azurewebsites.net/api";
  // IMAGE_ROOT = "https://healthswimstagingcontent.blob.core.windows.net/images/";
  // HOME_ROOT = "https://staging.healthswim.com";
  // PATIENT_ROOT =
  //   "https://healthswimstagwebpatient.z19.web.core.windows.net/login?";
  // SHARE_LINK_LOGIN = "https://staging.app.healthswim.com/loginPage";
  // SHARE_LINK_REGISTER = "https://staging.app.healthswim.com/registerinfoPage";

  // new production
  API_ROOT = "https://healthswim-production-api.azurewebsites.net/api";
  IMAGE_ROOT = "https://healthswimprodcontent.blob.core.windows.net/images/";
  HOME_ROOT = "https://www.healthswim.com";
  PATIENT_ROOT =
    "https://healthswimswebpatient.z19.web.core.windows.net/login?";
  SHARE_LINK_LOGIN = "https://app.healthswim.com/loginPage";
  SHARE_LINK_REGISTER = "https://app.healthswim.com/registerinfoPage";
} else {
  //API_ROOT = 'https://healthswimnew.azurewebsites.net/api';
  // API_ROOT = "http://localhost:58530/api";
  //production
  // API_ROOT = "https://healthswimapi-v3.azurewebsites.net/api";

  //staging
  // API_ROOT = "https://healthswimapi20220620214913.azurewebsites.net/api";
  // IMAGE_ROOT = "https://healthswimstagingcontent.blob.core.windows.net/images/";
  // HOME_ROOT = "https://staging.healthswim.com";
  // PATIENT_ROOT =
  //   "https://healthswimstagwebpatient.z19.web.core.windows.net/login?";
  // SHARE_LINK_LOGIN = "https://staging.app.healthswim.com/loginPage";
  // SHARE_LINK_REGISTER = "https://staging.app.healthswim.com/registerinfoPage";
  // // new production
  API_ROOT = "https://healthswim-production-api.azurewebsites.net/api";
  IMAGE_ROOT = "https://healthswimprodcontent.blob.core.windows.net/images/";
  HOME_ROOT = "https://www.healthswim.com";
  PATIENT_ROOT =
    "https://healthswimswebpatient.z19.web.core.windows.net/login?";
  SHARE_LINK_LOGIN = "https://app.healthswim.com/loginPage";
  SHARE_LINK_REGISTER = "https://app.healthswim.com/registerinfoPage";
}
// const API_ROOT = 'https://healthswimapi.azurewebsites.net/api/'

const imageUrl = () => {
  return IMAGE_ROOT;
};
const get = (url) => {
  const promise = new Promise((resolve, reject) => {
    Axios({
      method: "get",
      url: `${API_ROOT}/${url}`,
      headers: {
        // Authorization: `Bearer ` + tokenService.getToken(),
        "access-control-allow-origin": "*",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Methods": "*",
        email: localStorage.getItem("username"),
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      });
  });

  return promise;
};

const post = (url, data) => {
  const promise = new Promise((resolve, reject) => {
    Axios({
      method: "post",

      data: data,

      url: `${API_ROOT}/${url}`,
      headers: {
        // Authorization: `Bearer ` + tokenService.getToken(),
        // "content-type": "application/json",
        // Accept: "application/json",
        "access-control-allow-origin": "*",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Methods": "*",
        email: localStorage.getItem("username"),
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      });
  });

  return promise;
};

const deleteRequest = (url, data) => {
  const promise = new Promise((resolve, reject) => {
    Axios({
      method: "delete",
      url: `${API_ROOT}/${url}`,
      data: data,
      headers: {
        // Authorization: `Bearer ` + tokenService.getToken(),
        "access-control-allow-origin": "*",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Methods": "*",
        email: localStorage.getItem("username"),
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      });
  });

  return promise;
};

const baseApiService = {
  get,
  post,
  deleteRequest,
  imageUrl,
};

export default baseApiService;
